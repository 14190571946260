/** DO NOT MODIFY THIS FILE IS OVERWRITTEN VIA Saguaro CLI */
export const CONFIG = {
    "online": true,
    "debug": false,
    "demo": false,
    "webapp": true,
    "dev_url": "",
    "sitename": "logistics",
    "sitename_uppercase": "VR Centre Fleet",
    "version": "4.4.5",
    "short_version": "445",
    "build_timestamp": 1730800880246,
    "testing": false,
    "staging": false,
    "sentry": true
};